import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import TagCard from '../../components/TagCard';

import SidebarWrapper from '../../components/PageLayout/Sidebar';
import PostCard from '../../components/PostCard';
import SEO from '../../components/Seo';

function doSomething () {

  console.log("hello")

}

const Science = ({ data }) => (

    <Layout className="outerPadding">
    <Layout className="container">
      <Header />
      <SEO
        title="Teacher Trelyn's PSLE Materials"
        description="20 Years of Tutoring Experience"
        path="/"
      />
      <SidebarWrapper>

      <div className="marginTopTitle">

          <h1 className="titleSeparate">Topics</h1>

          <Row gutter={[20, 20]}>
          <ul>

            <li><a href='#sectionHeat'>Heat</a></li>
            <li><a href='#sectionEnergy'>Energy</a></li>
            <li><a href='#sectionForces'>Forces</a></li>
            <li><a href='#sectionCells'>Cells</a></li>
            <li><a href='#sectionElectricity'>Electricity</a></li>
            <li><a href='#sectionBodySystems'>Body System</a></li>
            <li><a href='#sectionAir'>Air and Respiration</a></li>
            <li><a href='#sectionCir'>Circulatory System</a></li>
            <li><a href='#sectionDiv'>Diversity</a></li>
            <li><a href='#sectionLight'>Light</a></li>
          </ul>


          <ul>
            <li><a href='#sectionLC'>Life Cycle</a></li>
            <li><a href='#sectionRep'>Reproduction</a></li>
            <li><a href='#sectionMatter'>Matter</a></li>
            <li><a href='#sectionWater'>Water</a></li>
            <li><a href='#sectionPlant'>Plant System</a></li>
            <li><a href='#sectionMagnets'>Magnets</a></li>
            <li><a href='#sectionMaterials'>Materials</a></li>
 
            <li><a href='#sectionAdap'>Adaptation in Animals</a></li>
            <li><a href='#sectionLiving'>Living Together</a></li>
            <li><a href='#sectionImp'>Man's Impact</a></li>

            
            
          </ul>

          </Row>
          
        </div>
        <br />

        <div id='sectionHeat' className="marginTopTitle">
          <h1 className="titleSeparate">Heat</h1>
        </div>
        <Row gutter={[20, 20]}>
          { 
            data.heat && data.heat.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>
        <br/>
        
        <div id='sectionEnergy' className="marginTopTitle">
          <h1 className="titleSeparate">Energy</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.energy && data.energy.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionForces' className="marginTopTitle">
          <h1 className="titleSeparate">Forces</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.forces && data.forces.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionCells' className="marginTopTitle">
          <h1 className="titleSeparate">Cells</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.cells && data.cells.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionElectricity' className="marginTopTitle">
          <h1 className="titleSeparate">Electricity</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.electricity && data.electricity.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionBodySystems' className="marginTopTitle">
          <h1 className="titleSeparate">Body Systems</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.bodysystems && data.bodysystems.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionAir' className="marginTopTitle">
          <h1 className="titleSeparate">Air and Respiratory</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.air && data.air.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionCir' className="marginTopTitle">
          <h1 className="titleSeparate">Circulatory System</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.cir && data.cir.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionDiv' className="marginTopTitle">
          <h1 className="titleSeparate">Diversity</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.div && data.div.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionLight' className="marginTopTitle">
          <h1 className="titleSeparate">Light</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.light && data.light.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionLC' className="marginTopTitle">
          <h1 className="titleSeparate">Life Cycle</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.lc && data.lc.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionRep' className="marginTopTitle">
          <h1 className="titleSeparate">Reproduction</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.rep && data.rep.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionMatter' className="marginTopTitle">
          <h1 className="titleSeparate">Matter</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.matter && data.matter.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionWater' className="marginTopTitle">
          <h1 className="titleSeparate">Water Cycle</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.water && data.water.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionPlant' className="marginTopTitle">
          <h1 className="titleSeparate">Plant System</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.plants && data.plants.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionMagnets' className="marginTopTitle">
          <h1 className="titleSeparate">Magnets</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.magnet && data.magnet.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>

        <div id='sectionMaterials' className="marginTopTitle">
          <h1 className="titleSeparate">Materials</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.materials && data.materials.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>
        <br/>


        <div id='sectionAdap' className="marginTopTitle">
          <h1 className="titleSeparate">Adaption in Animals</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.adaptation && data.adaptation.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>

        <br/>

        <div id='sectionLiving' className="marginTopTitle">
          <h1 className="titleSeparate">Living Together</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.living && data.living.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>

        <br/>

<div id='sectionImp' className="marginTopTitle">
          <h1 className="titleSeparate">Man's Impact</h1>
        </div>

        <Row gutter={[20, 20]}>
          {
            data.imp && data.imp.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={12} sm={12} md={12} lg={8}>
                                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />
              </Col>
            ))
          }
        </Row>

      </SidebarWrapper>

    </Layout>
  </Layout>
  
);

Science.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
};

export const data = graphql`
query {

  heat: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Heat"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  energy: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Energy"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  energy: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Energy"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  forces: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Forces"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  cells: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Cells"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  electricity: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Electricity"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  bodysystems: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Body Systems"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  air: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Air and Respiratory"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  cir: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Circulatory System"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  div: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Diversity"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  light: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Light"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  lc: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Life Cycle"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  rep: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Reproduction"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  matter: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Matter"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  water: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Water"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  plants: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Plants"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  magnet: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Magnets"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
  materials: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Materials"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  adaptation: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Adaptation"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  living: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Living"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  imp: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "Science"}, topic: {eq: "Impact"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }
}
`;
export default Science;
